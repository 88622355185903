<template>
  <v-progress-circular
      :size="size"
      :width="width"
      :value="value"
      color="primary"
  >
    {{ value }}%
  </v-progress-circular>
</template>

<script>
export default {
  name: "ProgressPercentage",

  props: {
    size: { type: Number, default: 70 },
    width: { type: Number, default: 10 },
    value: { type: Number, default: 0, required: true },
  }
}
</script>

<style scoped>

</style>
